import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import LcView from '../views/LcView.vue'
import RecoveryView from '../views/RecoveryView.vue'
import LinkAuthComponent from '../components/LinkAuthComponent.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/lc',
    name: 'lc',
    component: LcView
  },
  {
    path: '/redirect',
    name: 'link_redirect',
    component: LinkAuthComponent
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: RecoveryView
  }
]

const router = new VueRouter({
  routes
})

export default router
