<template>
    <div class="wrapper">
        <div v-if="status=='idle'">
            <div v-if="qrcodes && qrcodes.length==0">
                <p>Для участия в розыгрыше загрузите или сфотографируйте свой чек</p>
                
            </div>
            <div v-else>
                <p>Сканированные чеки</p>
                <v-btn
                depressed
                class="next-btn"
                block
                @click="current_qr = qr;status='qr'"
                v-for="qr in qrcodes"
                :key="qr.id"
                >
                    <template>
                        <p>№{{qr.id}}</p>
                    </template>
                </v-btn>
            </div>
            <v-btn
                depressed
                color="primary"
                class="next-btn"
                block
                @click="startScan"
                >Сканировать чек</v-btn>
            <p class="recovery">Подробнее об акции</p>
        </div>

        <div class="scan">
            <video class="scanner"></video>
            <p>Убедитесь что в области отчётливо виден QR-код</p>
            <p class="recovery" @click="status='file';$el.querySelector('.scan').classList.remove('active');qrScanner.stop()">QR-код не отображается</p>
        </div>

        <div v-if="status=='file'">
            <p>Если отсканировать QR-код не получилось, загрузите фото чека вручную</p>
            <div>
                <input type="file" id="qr" ref="uploader" @change="onFileChanged">
                <v-btn
                depressed
                color="primary"
                class="next-btn"
                block
                :loading="isSelecting"
                @click="handleFileImport"
                >Загрузить фото чека</v-btn>
            </div>
        </div>

        <div v-if="status=='success'">
            <p>Чек принят на рассмотрение</p>
            <p>Проверка чека происходит в течение 24 часов. Рекомендуем сохранять чек до подтверждения</p>
            <v-btn
                depressed
                color="primary"
                class="next-btn"
                block
                @click="getQrs();status='idle'"
                >Ок</v-btn>
            <v-btn
                depressed
                color="primary"
                class="next-btn"
                block
                @click="startScan()"
                >Сканировать новый чек</v-btn>
        </div>
        <div v-if="status=='qr'">
        <p>Чек №{{current_qr.id}}</p>
        <div>
            <div>
                <p>Дата загрузки</p>
                <v-text-field
                    outlined
                    :value="current_qr.create_date"
                    dense
                    disabled
                ></v-text-field>
            </div>
            <div>
                <p>ФД - номер</p>
                <v-text-field
                    outlined
                    :value="current_qr.fd?current_qr.fd:''"
                    dense
                    disabled
                ></v-text-field>
            </div>
            <div>
                <p>Статус проверки</p>
                <v-text-field
                    outlined
                    :value="statuses[current_qr.status]"
                    dense
                    disabled
                ></v-text-field>
            </div>
        </div>
        <v-btn
                depressed
                color="primary"
                class="next-btn"
                block
                @click="startScan"
                >Сканировать чек</v-btn>
            <p class="recovery">Подробнее об акции</p>
    </div>
        <canvas id="canvas" style="overflow:auto"></canvas>
    </div>
</template>
<script>
import jwt_decode from 'jwt-decode'
import QrScanner from 'qr-scanner';
export default {
    data(){
            return {
                isSelecting: false,
                selectedFile: null,
                code: null,
                qrScanner: null,
                status:'idle',
                user_id: jwt_decode(this.$cookies.get('cng_token'))['id'],
                qrcodes: [],
                current_qr: null,
                statuses: {
                    'created': 'В обработке',
                    'accepted': 'Принято'
                }
            }
        },
    methods: {
        handleFileImport() {
            this.isSelecting = true;
            window.addEventListener('focus', () => {
                this.isSelecting = false
            }, { once: true });
            this.$refs.uploader.click();
        },
        onFileChanged(e) {
            this.selectedFile = e.target.files[0];
            let formData = new FormData()
            formData.append('file', this.selectedFile)
            formData.append('account_id', this.user_id)
            fetch(`${process.env.VUE_APP_QR}`, {
                method:'POST',
                body: formData
            })
            .then(response=>{
                this.status='success'
                return response.json()
            })
        },
        startScan(){
            this.status = 'scan'
            this.$el.querySelector('.scan').classList.add('active')
            if(!this.qrScanner){
                let videoElem = this.$el.querySelector('.scanner')
                this.qrScanner = new QrScanner(
                    videoElem,
                    result => {
                        let res = this.decodeStr(result.data)
                        if(!res){
                            // Неподходящий формат
                            return
                        }
                        if(!res.fn || !res.fp || !res.i || !res.n || !res.s || !res.t){
                            // Отствствуют данные
                            return
                        }

                        function dataURItoBlob(dataURI) {
                        // convert base64/URLEncoded data component to raw binary data held in a string
                        var byteString;
                        if (dataURI.split(',')[0].indexOf('base64') >= 0)
                            byteString = atob(dataURI.split(',')[1]);
                        else
                            byteString = unescape(dataURI.split(',')[1]);
                        // separate out the mime component
                        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
                        // write the bytes of the string to a typed array
                        var ia = new Uint8Array(byteString.length);
                        for (var i = 0; i < byteString.length; i++) {
                            ia[i] = byteString.charCodeAt(i);
                        }
                        return new Blob([ia], {type:mimeString});
                        }

                        var canvas = document.getElementById("canvas");
                        var video = document.querySelector("video");
                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;
                        canvas
                        .getContext("2d")
                        .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                        this.selectedFile = dataURItoBlob(canvas.toDataURL())
                        this.qrScanner.stop()
                        let formData = new FormData()
                        formData.append('file', this.selectedFile)
                        formData.append('account_id', this.user_id)
                        formData.append('time', res.t)
                        formData.append('operation', res.n)
                        formData.append('summary', res.s)
                        formData.append('fd', res.i)
                        formData.append('fn', res.fn)
                        formData.append('fp', res.fp)
                        fetch(`${process.env.VUE_APP_QR}`, {
                            method:'POST',
                            body: formData
                        })
                        .then(response=>{
                            if(response.ok){
                                this.status = 'success'
                                return response.json()
                            }
                            else {
                                this.qrScanner.start()
                                return
                            }
                            
                            
                            
                        })
                    },
                    {returnDetailedScanResult: true},
                );
            }
            this.qrScanner.start();
            
        },
        decodeStr(str){
            console.log(str)
            try{
                let kv = str.split('&')
                let res = {}
                for(let i=0;i<kv.length;i++){
                    let tmp = kv[i].split('=')
                    res[tmp[0]] = tmp[1]
                }
                return res
            }
            catch(e){
                console.log(e)
                return false
            }
            
        },
        getQrs(){
            fetch(`${process.env.VUE_APP_QR}`, {
            method:'GET'
            })
            .then(response=>{
                return response.json()
            })
            .then(data=>{
                this.qrcodes = data.data
            })
        },
    },
    mounted(){
        let token = this.$cookies.get('cng_token')
        if(!token){
            this.$router.push({name:'login'})
        }
        this.getQrs()
    }
}
</script>
<style>
.recovery {
    margin-top: 12px;
    color: #044EF3;
    font-size: 12px !important;
    cursor: pointer;
}
.wrapper {
    width: 365px;
    padding: 61.5px 22.5px;
    margin: 128px auto;
}
.scanner {
    width: 256px !important;
    height: 256px !important;
}
input[type='file'] {
    display: none;
}
canvas {
    display: none;
}
.scan {
    display: none;
}
.scan.active {
    display: block;
}
</style>