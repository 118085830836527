<template>
    <div></div>
</template>
<script>
import jwt_decode from 'jwt-decode'
export default {
    mounted(){
        if(this.$route.query.token){
            let token = this.$cookies.get('cng_token')
            if(token){
                return
            }
            try{
                if(jwt_decode(this.$route.query.token)){
                this.$cookies.set('cng_token', this.$route.query.token)
                this.$router.push({name:'lc'})
            }
            }
            catch(e){
                //dsfs
            }
            
        }
    }
}
</script>