<template>
    <div class="wrapper">
        <v-form ref="form" lazy-validation v-if="!recovery">
            <div v-if="!user_found && !registration && !reg_success">
                <p>Зарегистрируйте свои чеки <br>для участия в розыгрыше!</p>
                <p>Введите ваш email</p>
            </div>
            <p v-if="user_found && !registration && !reg_success">Вход в личный кабинет</p>
            <p v-if="!user_found && registration && !valid_password && !reg_success">Придумайте пароль</p>
            <p v-if="valid_password && registration && valid && !reg_success">Повторите пароль</p>
            <p v-if="reg_success">На вашу почту было отправлено подтверждение, перейдите по ссылке в письме</p>
            <v-text-field
                v-model="email"
                placeholder="Email"
                dense
                outlined
                class="email-field"
                :rules="emailRules"
                required
                @input="emailRules=[]"
                clearable
                :disabled="valid?true:false"
                v-if="!reg_success"
            >
            </v-text-field>
            <v-text-field
                v-model="password"
                placeholder="Password"
                dense
                :rules="password_rules"
                outlined
                class="password-field"
                type="password"
                required
                clearable
                v-if="valid && !reg_success"
                :hint="registration?'Пароль должен состоять из восьми или более символов латинского алфавита, содержать заглавные и строчные буквы, цифры':''"
                @blur="()=>{if(password) valid_password=true; else {valid_password=false; repeat=''}}"
                @input="()=>{if(!password) {valid_password=false; repeat=''}}"
            ></v-text-field>
            <v-text-field
                v-model="repeat"
                placeholder="Repeat"
                dense
                outlined
                class="password-field"
                type="password"
                required
                clearable
                v-if="valid_password && registration && valid && !reg_success"
                :rules="repeat_rules" 
                @input="repeat_rules=[]"
            >
            </v-text-field>
            <v-btn
            depressed
            :color="email?'primary':''"
            class="next-btn"
            block
            :disabled="(!registration && !logining && email) || (!registration && logining && email && password) || (registration && email && password && repeat)?false:true"
            @click="(!registration && !logining && !reg_success)?validate():(registration && !reg_success)?register():(logining && !reg_success)?login():reg_success?drop():''"
            >Войти</v-btn>
            <p class="recovery" v-if="valid" @click="recovery=true">Забыли пароль?</p>
        </v-form>
        <v-form v-if="recovery" ref="form">
            <div>
                <p>Восстановление пароля</p>
            </div>
            <div v-if="!recovery_send">
                <p class="note">Письмо со ссылкой для восстановления пароля будет отправлено на указанную почту</p>
                <v-text-field
                    v-model="email"
                    placeholder="Email"
                    dense
                    outlined
                    class="email-field"
                    :rules="emailRules"
                    required
                    @input="emailRules=[]"
                    clearable
                    v-if="!reg_success"
                />
                <v-btn
                depressed
                :color="email?'primary':''"
                class="next-btn"
                block
                @click="sendRecovery"
                >Отправить</v-btn>
            </div>
            <div v-if="recovery_send">
                <p class="note">Письмо с ссылкой на восстановление было отправлено на почту</p>
            </div>
            <p class="recovery" v-if="valid" @click="recovery=false">Войти в систему</p>
        </v-form>
    </div>
</template>
<script>
import jwt_decode from "jwt-decode"
export default {
    data(){
        return {
            email: '',
            password: '',
            repeat: '',
            emailRules: [],
            valid: false,
            valid_password: false,
            user_found: false,
            user_id: undefined,
            registration: false,
            logining: false,
            repeat_rules: [],
            reg_success: false,
            password_rules: [],
            recovery: false,
            recovery_send: false
        }
    },
    methods: {
        drop: function(){
            this.email= '',
            this.password= '',
            this.repeat= '',
            this.emailRules= [],
            this.valid= false,
            this.valid_password= false,
            this.user_found= false,
            this.user_id= undefined,
            this.registration= false,
            this.logining= false,
            this.repeat_rules= [],
            this.reg_success= false,
            this.password_rules= []
        },
        sendRecovery (){
            this.emailRules = [
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Некорректный email'
            ]
            this.$nextTick(() => {
                if(this.$refs.form.validate()) {
                    fetch(`${process.env.VUE_APP_MYHOST}/recovery`, {
                        method:'POST',
                        headers:{
                            'Content-Type':'application/json'
                        },
                        body: JSON.stringify({
                            email: this.email,
                        })
                    })
                    .then(response=>{
                        return response.json()
                    })
                    .then(data=>{
                        if(data.data && data.data.id){
                            this.recovery_send = true

                        }
                        else {
                            this.emailRules = ['Данный email не зарегистрирован']
                        }
                    })
                }
            })
        },
        validate () {
            this.emailRules = [
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Некорректный email'
            ]
            this.$nextTick(() => {
                if(this.$refs.form.validate()) {
                    this.valid = true
                    fetch(`${process.env.VUE_APP_MYHOST}/${this.email}`,{
                        method:'GET'
                    })
                    .then(response=>{
                        return response.json()
                    })
                    .then(data=>{
                        if(data.data && data.data.id){
                            this.logining = true
                            this.user_found = true
                            this.user_id = data.data.id
                        }
                        else {
                            this.registration=true
                        }
                    })
                }
            })
        },
        register: function(){
            if(this.password!=this.repeat){
                this.repeat_rules = ["Пароли не совпадают"]
                return
            }
            if(this.password.length<9){
                this.repeat_rules = ["Пароли меньше 8 символов"]
                return
            }
            fetch(`${process.env.VUE_APP_MYHOST}`, {
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body: JSON.stringify({
                    email: this.email,
                    password: this.password
                })
            })
            .then(response=>{
                if(response.ok){
                    this.reg_success = true
                }
                else {
                    alert("Произошла ошибка")
                }
            })
        },
        login: function(){
            if(this.password.length==0){
                this.password_rules = ["Обязательное поле"]
                return
            }
            fetch(`${process.env.VUE_APP_MYHOST}/validate`, {
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body: JSON.stringify({
                    email: this.email,
                    password: this.password
                })
            })
            .then(response=>{
                return response.json()
            })
            .then(data=>{
                if(data.type=='error'){
                    alert("Неверный логин или пароль")
                }
                else {
                    this.$cookies.set('cng_token',data.data.jwt)
                    this.$router.push({name:'lc'})
                }
            })
        }
    },
    mounted(){
        let token = this.$cookies.get('cng_token')
        if(token && jwt_decode(token).id){
            this.$router.push({name:'lc'})
        }
    }
}
</script>
<style scoped>
p.note {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 130% !important;
}
.recovery {
    margin-top: 12px;
    color: #044EF3;
    font-size: 12px !important;
    cursor: pointer;
}
.wrapper {
    width: 365px;
    border: 1px solid #E5E5E5;
    border-radius: 16px;
    padding: 61.5px 22.5px;
    margin: 128px auto;
}
.wrapper p {
    font-size: 18px;
    font-weight: 700;
}
.next-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}
</style>