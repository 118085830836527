<template>
    <div class="wrapper">
        <v-form ref="form" lazy-validation>
            <div>
                <p>Восстановление пароля</p>
            </div>
            <p class="note">Введите новый пароль для вашего аккаунта</p>
            <v-text-field
                v-model="password"
                placeholder="Password"
                dense
                :rules="password_rules"
                outlined
                class="password-field"
                type="password"
                required
                clearable
                :hint="'Пароль должен состоять из восьми или более символов латинского алфавита, содержать заглавные и строчные буквы, цифры'"
            ></v-text-field>
            <v-text-field
                v-model="repeat"
                placeholder="Repeat"
                dense
                outlined
                class="password-field"
                type="password"
                required
                clearable
                :rules="repeat_rules" 
                @input="repeat_rules=[]"
            >
            </v-text-field>
            <v-btn
            depressed
            :color="(password && repeat)?'primary':''"
            class="next-btn"
            block
            @click="doRecovery"
            >Сохранить</v-btn>
            <p class="recovery" @click="$router.push({name:'login'})">Войти в систему</p>
        </v-form>
    </div>
</template>
<script>
import jwt_decode from "jwt-decode"
export default {
    data(){
        return {
            password: '',
            repeat: '',
            repeat_rules: [],
            password_rules: [],
            user_id:''
        }
    },
    methods: {
        doRecovery (){
            if(this.password!=this.repeat){
                this.repeat_rules = ["Пароли не совпадают"]
                return
            }
            if(this.password.length<8){
                this.repeat_rules = ["Пароли меньше 8 символов"]
                return
            }
            this.$nextTick(() => {
                if(this.$refs.form.validate()) {
                    fetch(`${process.env.VUE_APP_ACCOUNTS}/${this.user_id}`, {
                        method:'PATCH',
                        headers:{
                            'Content-Type':'application/json'
                        },
                        body: JSON.stringify({
                            password: this.password,
                        })
                    })
                    .then(response=>{
                        return response.json()
                    })
                    .then(data=>{
                        if(data.data){
                            this.$router.push({name:'login'})
                        }
                        else {
                            this.password_rules = ['Произошла ошибка. Попробуйте позже']
                        }
                    })
                }
            })
        },
    },
    mounted(){
        if(!this.$route.query.token){
            this.$router.push({name:'login'})
            return
        }
        let id = jwt_decode(this.$route.query.token)['id']
        if(!id){
            this.$router.push({name:'login'})
            return
        }
        fetch(`${process.env.VUE_APP_ACCOUNTS}/${id}`, {
            method:'GET'
        })
        .then(response=>{
            return response.json()
        })
        .then(data=>{
            if(!data.data){
                this.$router.push({name:'login'})
                return
            }
            this.user_id = id
        })
    }
}
</script>
<style scoped>
p.note {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 130% !important;
}
.recovery {
    margin-top: 12px;
    color: #044EF3;
    font-size: 12px !important;
    cursor: pointer;
}
.wrapper {
    width: 365px;
    border: 1px solid #E5E5E5;
    border-radius: 16px;
    padding: 61.5px 22.5px;
    margin: 128px auto;
}
.wrapper p {
    font-size: 18px;
    font-weight: 700;
}
.next-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}
</style>